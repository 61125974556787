<template>
  <el-card style="min-height: 45rem">
    <el-row :gutter="40">
      <el-col :span="12">
        <el-form label-position="top">
          <el-form-item v-if="form.type === 1" label="视频缩略图">
            <y_upload_img v-model="form.pic"></y_upload_img>
          </el-form-item>
          <el-form-item label="课程内容">
            <y_editor v-if="form.type === 3" v-model="form.detail"></y_editor>
            <y_upload_video v-if="form.type === 1" v-model="form.detail"></y_upload_video>
            <template v-if="form.type === 2">
              <y_upload_audio  v-model="form.detail"></y_upload_audio>
            </template>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form>
          <el-form-item label="章节标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="章节类型">
            <el-radio-group :disabled="form.id > 0" @change="form.detail = ''" v-model="form.type">
              <el-radio :label="1">视频</el-radio>
              <el-radio :label="2">音频</el-radio>
              <el-radio :label="3">图文</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="[1,2].includes(form.type)" label="时长">
            <el-input v-model="form.time"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="edit" type="primary">提交</el-button>
            <el-button @click="$router.back()">返回列表</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_upload_video from "@/components/y-form/y-upload/y_upload_video";
import y_upload_audio from "@/components/y-form/y-upload/y_upload_audio";
export default {
  components:{
    y_editor,
    y_upload_img,
    y_upload_video,
    y_upload_audio,
  },
  name: "edit",
  data(){
    return{
      form:{
        course_id:0,
        title:"",
        pic:"",
        time:"",
        detail:"",
        type:1,
      },
      edit_id:0,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  methods:{
    load(){
      this.$u.api.shop.plugin.knowledge.courseColumnOne({id:this.edit_id}).then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.form.course_id = parseInt(this.$route.query.course_id);
      this.$u.api.shop.plugin.knowledge.courseColumnEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
  },
}
</script>

<style scoped>

</style>